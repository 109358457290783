import React, { Dispatch, FunctionComponent, ReactElement, SetStateAction, useEffect, useRef, useState } from 'react';
import { Breadcrumb, Button, Checkbox, Col, Descriptions, Dropdown, Flex, Form, Input, Layout, List, Menu, MenuProps, Row, Select, Tooltip, Upload, message, theme } from 'antd';
import Sider from 'antd/es/layout/Sider';
import { Header, Content, Footer } from 'antd/es/layout/layout';

import { UploadOutlined, AppstoreAddOutlined, DeleteOutlined, ClearOutlined } from '@ant-design/icons'

import CryptoJS from 'crypto-js';
import { ValidateStatus } from 'antd/es/form/FormItem';
import { ServiceProp } from '../types/service';
import { Item } from '../types/item';
import { glvs } from '../types/glv';
import Cookies from "js-cookie";

const FooterFC: FunctionComponent<{showmenu:boolean}> = ({showmenu=false}): ReactElement => {
    const _acc = Cookies.get('acc')
    const {token}=theme.useToken()
    const [messageApi,contextHolder]=message.useMessage()

    return <Footer style={{ textAlign: 'center',background: token.colorBgContainer }}><span>lubupang Design ©2023 Created by 陆不胖</span><span style={{
        marginLeft:"10px",
        marginRight:"10px",
        opacity:"0.4"
    }}>|</span> {showmenu&&(<span ><a href='https://beian.miit.gov.cn/#/Integrated/index' target='_blank'>沪ICP备2024065446号-1</a><span style={{
        marginLeft:"10px",
        marginRight:"10px",
        opacity:"0.4"
    }}>|</span><a href="https://beian.mps.gov.cn/#/query/webSearch?code=31011702889509" rel="noreferrer" target="_blank">沪公网安备31011702889509</a></span>)}</Footer>

}
FooterFC.defaultProps={
    showmenu:false
}
export default FooterFC

