import React, { Dispatch, FunctionComponent, ReactElement, SetStateAction, useEffect, useRef, useState } from 'react';
import { Breadcrumb, Button, Checkbox, Col, Descriptions, Dropdown, Flex, Form, Input, Layout, List, Menu, MenuProps, Row, Select, Tooltip, Upload, message, theme } from 'antd';
import Sider from 'antd/es/layout/Sider';
import { Header, Content, Footer } from 'antd/es/layout/layout';

import { UploadOutlined, AppstoreAddOutlined, DeleteOutlined, ClearOutlined } from '@ant-design/icons'

import CryptoJS from 'crypto-js';
import { ValidateStatus } from 'antd/es/form/FormItem';
import { ServiceProp } from '../types/service';
import { Item } from '../types/item';
import { glvs } from '../types/glv';
import Cookies from "js-cookie";

const HeaderFC: FunctionComponent<{showmenu:boolean}> = ({showmenu=false}): ReactElement => {
    const _acc = Cookies.get('acc')
    
    const [messageApi,contextHolder]=message.useMessage()

    return <Header style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
        
        <a style={{ color: "white",fontSize:"16px" }} href="/home"  >陆不胖科技发展有限公司</a>
        {showmenu&&(<div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center",
            fontSize:"13px" }}>
        <a style={{
            marginLeft:"20px",
            color: "white"
        }} href='/tutorial'
        > 产品教程</a>
        <a style={{
            marginLeft:"20px",
            color: "white"
        }} href="/company"> 关于我们 </a>
         <a style={{
            marginLeft:"20px",
            color: "white"
        }}  href="/cooperate"> 代销合作 </a>

        </div>)}
        </div>
        


        {_acc ? (<div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center",
            fontSize:"13px" }}><a href="/my" target='_blank' style={{ paddingRight: "20px" }}>{_acc} 您好！</a> <a href='/logout' onClick={
            ()=>{
                Cookies.remove('acc')
            }
        }>注销</a></div>) : <div style={{  display: "flex", justifyContent: "flex-end", alignItems: "center" ,
        fontSize:"13px"}}><a href="/register" target='_self' style={{ paddingRight: "20px" }}>注册</a><a href="/login" target='_self'>登陆</a></div>}
        {contextHolder}
    </Header>
}
HeaderFC.defaultProps={
    showmenu:false
}
export default HeaderFC

