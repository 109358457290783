import React from 'react';
import ReactDOM from 'react-dom/client';
import './home.css';
import App from '../../App';
import {TableConfig,OptionConfig} from '../../types'

import reportWebVitals from '../../reportWebVitals';
import Home from '../../lubupang/apps/home';
import Cookies from 'js-cookie';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    {window.innerWidth>=720? <Home />:<>为了您的体验请使用分辨率大于720的设备访问本网站</>}

</React.StrictMode>
)


