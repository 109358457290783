import React, { Dispatch, FunctionComponent, ReactElement, SetStateAction, useEffect, useRef, useState } from 'react';
import { Breadcrumb, Button, Checkbox, Col, Descriptions, Dropdown, Flex, Form, Input, Layout, List, Menu, MenuProps, Radio, Row, Select, Tooltip, Upload, message, theme } from 'antd';
import Sider from 'antd/es/layout/Sider';
import { Header, Content, Footer } from 'antd/es/layout/layout';

import { UploadOutlined, AppstoreAddOutlined, DeleteOutlined, ClearOutlined } from '@ant-design/icons'

import CryptoJS from 'crypto-js';
import { ValidateStatus } from 'antd/es/form/FormItem';
import { ServiceProp } from '../types/service';
import { Item } from '../types/item';
import { glvs } from '../types/glv';
import ServiceFC from '../components/servicefc';
import HeaderFC from '../components/headerfc';
import FloorFC from '../components/footerfc'
import { getusertype } from '../constans/c';
import { UserType } from '../types/usertype';






const Home: FunctionComponent = (): ReactElement => {
    
    const {
        token: { colorBgContainer },
      } = theme.useToken();
      const [collapsed, setCollapsed] = useState(false);

   

 
return <>
<Layout style={{ minHeight: '100vh'}}>
<HeaderFC  showmenu={true}/>

<Layout >

  <div style={{
    width:'50%',

    margin:"auto",
    display:"flex",
    flexDirection:'column'
  }}><span style={{
    fontSize:"40px"
  }}> 您的网页数据"云盘"，自动把网页数据上云，随时可下载</span>
  <div style={{
        display:"flex",
        flexDirection:'column'
  }}>
    <span>目前支持的网站如下:</span>
  <ul>
    <li>生意参谋（部分）<a href="https://sycm.taobao.com" target='_blank'>https://sycm.taobao.com</a></li>
    <li>数据银行（部分）<a href="https://databank.tmall.com" target='_blank'>https://databank.tmall.com</a> </li>
    <li>策略中心（部分）<a href="https://strategy.tmall.com" target='_blank'>https://strategy.tmall.com</a></li>
    <li>达摩盘（部分）<a href="https://dmp.taobao.com" target='_blank'>https://dmp.taobao.com</a></li>
  </ul>
  </div>

  <span  style={{
    fontSize:"16px",
    opacity:"0.5",
  }}>这已经是技术人的巅峰设计水平了&#128514;</span>  
  </div>

  
</Layout>
<FloorFC showmenu={true}/>
</Layout>
</>



}

export default Home